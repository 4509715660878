import * as React from 'react';
import axios from 'axios';

import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';

import Breakpoint from '../../styles/Breakpoints';

import UseDimensions from '../../services/UseDimensions';

import Config from '../../Config';

import TextBox from './TextBox';

import Input from '../Modules/Forms/Input';
import Label from '../Modules/Text/Label';
import Buttons from '../Modules/Text/Buttons';
import Text from '../Modules/Text';

export default function BasketballsCool() {
  const size = UseDimensions();
  const { t } = useTranslation();

  const SITE_KEY = Config.GoogleRecaptha_SiteKey;

  const [email, setEmail] = React.useState('');
  const [emailConfirm, setEmailConfirm] = React.useState('');
  const [feedback, setFeedback] = React.useState('');
  const [sending, setSending] = React.useState(false);

  const submitForm = () => {
    if (email === '' || sending) return;

    setSending(true);

    window.grecaptcha.ready(() => {
      window.grecaptcha.execute(SITE_KEY, { action: 'submit' }).then((token) => {
        axios.post(`${Config.apiUrl}/send_email.php`, {
          name: 'no-reply@bourgondie.nl',
          email,
          subject: `[Basketball'sCool] Aanvraag/Informatie: ${email}`,
          message: `Dit is een automatisch gegenereerd bericht! <br /><br /> '${email}' heeft zich ingeschreven op www.bourgondie.nl`,
          reCaptchaToken: token,
        })
          .then(() => {
            setEmail('');
            setEmailConfirm('');
            setSending(false);
            setFeedback('Verzonden!');
          }, () => {
            setFeedback('Er is iets fout gegaan...');
          })
          .catch(() => {
            setSending(false);
          });
      });
    });
  };

  return (
    <div>
      <TextBox
        title={parse(t('BasketballsCool.title'))}
        text={parse(t('BasketballsCool.text'))}
      />
      <form
        style={{
          marginBottom: 20,
        }}
      >
        <div
          style={{
            marginTop: 30,
            marginBottom: 15,
          }}
        >
          <Label required>E-mailadres:</Label>
          <Input
            onChange={(e) => setEmail(e.target.value)}
            name="email"
            autocomplete="email"
            required
            value={email}
          />
        </div>
        {
          email !== '' && (
            <div
              style={{
                marginTop: 15,
                marginBottom: 30,
              }}
            >
              <Label required>Bevestiging e-mailadres:</Label>
              <Input
                onChange={(e) => setEmailConfirm(e.target.value)}
                name="email"
                autocomplete="email"
                required
                value={emailConfirm}
              />
            </div>
          )
        }
        <div>
          {
            feedback && (
              <div
                style={{
                  marginTop: 5,
                  marginBottom: 15,
                }}
              >
                <Text
                  fontWeight={300}
                  fontFamily="Poppins"
                >
                  {feedback}
                </Text>
              </div>
            )
          }
          <Buttons
            title="Versturen"
            disabled={email === '' || emailConfirm === ''}
            maxWidth={size.width < Breakpoint.sm ? '100%' : '48%'}
            onClick={() => ((email === '' || emailConfirm === '') ? null : submitForm())}
          />
        </div>
      </form>
      <Text
        fontFamily="Poppins"
        fontWeight="300"
        fontSize="0.8em"
      >
        {parse(t('Contact.legal'))}
      </Text>
    </div>
  );
}
