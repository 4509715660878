import * as React from 'react';

import Breakpoint from '../../styles/Breakpoints';
import UseDimensions from '../../services/UseDimensions';

export default function Container(props) {
  const size = UseDimensions();

  const breakpoint = props.breakpoint === 'sm' ? Breakpoint.sm : Breakpoint.md;

  const renderContainer = () => (
    <div style={{
      height: 'auto',
      width: '100%',
      display: 'flex',
      flexDirection: size.width < breakpoint ? (props.invert ? 'column-reverse' : 'column') : (props.invert ? 'row-reverse' : 'row'),
    }}
    >
      {
        /* eslint-disable */
        props.children.map((i, index) => (
          <div
            key={index}
            style={{
              width: size.width < breakpoint
                ? '100%'
                : props.share
                  ? (`${props.share[index] - (2 - (2 / props.children.length))}%`)
                  : (`${(100 / props.children.length) - (2 - (2 / props.children.length))}%`),
              marginRight: (props.invert ? (props.children.length !== (index + 1)) : props.children.length === (index + 1)) ? null : '2%',
            }}
          >
            {i}
          </div>
        ))
        /* eslint-enable */
      }
    </div>
  );

  return (
    <div style={{
      height: 'auto',
      width: '100%',
      ...props.style,
    }}
    >
      {renderContainer()}
    </div>
  );
}
