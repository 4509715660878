import * as React from 'react';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';

import Container from '../Containers/Container';

import Text from '../Modules/Text';

import Breakpoint from '../../styles/Breakpoints';
import Colors from '../../styles/Colors';

import UseDimensions from '../../services/UseDimensions';

import Buttons from '../Modules/Text/Buttons';

export default function Address() {
  const { t } = useTranslation();
  const size = UseDimensions();

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          marginTop: size.width < Breakpoint.md ? 50 : 0,
          borderBottom: `solid 4px ${Colors.BLACK_MEDIUM}`,
          paddingBottom: 5,
          height: 30,
          marginBottom: 20,
        }}
      />
      <div>
        <Text
          fontFamily="Poppins"
          fontWeight="300"
        >
          {parse(t('BasketballsCool.training_text'))}
        </Text>
        <br />
        <Container>
          <div>
            <Text>
              7 t/m 12 jaar
            </Text>
          </div>
          <div>
            <Text>
              17:15 - 18:15
            </Text>
          </div>
        </Container>
        <div
          style={{
            marginTop: 30,
          }}
        >
          <Text
            fontFamily="Poppins"
            fontWeight="300"
          >
            {parse(t('BasketballsCool.address'))}
          </Text>
          <div style={{ marginTop: 20, marginBottom: 60 }}>
            <Buttons
              type="secondary"
              title="Open in Google Maps"
              onClick={() => window.open('https://goo.gl/maps/iS6nU7bZRfjgJNc77')}
            />
          </div>
        </div>
      </div>
    </>
  );
}
