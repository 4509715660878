import * as React from 'react';

import UseDimensions from '../../services/UseDimensions';

import Colors from '../../styles/Colors';
import Breakpoint from '../../styles/Breakpoints';

import Text from '../Modules/Text';

export default function Standings({ data }) {
  const size = UseDimensions();
  const standings = data;

  const rm = (e) => {
    const team = e.replace(/(?:^|\W)Mannen Senioren [1-9][0-9]?$|^100(?:$|\W)/, '');
    if (team === 'Bourgondie') {
      return true;
    }
    return false;
  };

  return (
    <div
      style={{
        paddingBottom: 10,
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          marginTop: size.width < Breakpoint.md ? 50 : 0,
          borderBottom: `solid 4px ${Colors.BLACK_MEDIUM}`,
          paddingBottom: 5,
          height: 30,
        }}
      >
        <div
          style={{
            width: '10%',
          }}
        >
          <Text
            textAlign="center"
          >
            #
          </Text>
        </div>
        <div
          style={{
            width: '70%',
          }}
        >
          <Text
            textAlign="left"
            paddingLeft="10px"
          >
            Team
          </Text>
        </div>
        <div
          style={{
            width: '10%',
          }}
        >
          <Text
            textAlign="center"
          >
            G
          </Text>
        </div>
        <div
          style={{
            width: '10%',
          }}
        >
          <Text
            textAlign="center"
          >
            P
          </Text>
        </div>
      </div>

      <>
        { standings.map((item) => (
          <div
            key={item.rang}
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginTop: 20,
            }}
          >
            <div
              style={{
                width: '10%',
              }}
            >
              <Text
                textAlign="center"
                color={rm(item.team) ? Colors.ORANGE : Colors.WHITE}
              >
                {item.rang}
              </Text>
            </div>
            <div
              style={{
                width: '70%',
              }}
            >
              <Text
                textAlign="left"
                paddingLeft="10px"
                color={rm(item.team) ? Colors.ORANGE : Colors.WHITE}
              >
                {rm(item.team) ? 'Bourgondië' : item.team.replace(/(?:^|\W)Mannen Senioren [1-9][0-9]?$|^100(?:$|\W)/, '')}
              </Text>
            </div>
            <div
              style={{
                width: '10%',
              }}
            >
              <Text
                textAlign="center"
                color={rm(item.team) ? Colors.ORANGE : Colors.WHITE}
              >
                {item.gespeeld}
              </Text>
            </div>
            <div
              style={{
                width: '10%',
              }}
            >
              <Text
                textAlign="center"
                color={rm(item.team) ? Colors.ORANGE : Colors.WHITE}
              >
                {item.punten}
              </Text>
            </div>
          </div>
        ))}
      </>
    </div>
  );
}
