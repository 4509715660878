import * as React from 'react';

export default function FullScreenImage(props) {
  return (
    <div
      style={{
        minHeight: props.minHeight ?? '100vh',
        minWidth: props.minWidth ?? '100%',
        height: props.height ?? '100vh',
        width: props.width ?? '100%',
        backgroundImage: `url(${props.src})`,
        backgroundPosition: props.backgroundPosition ?? 'center',
        backgroundSize: props.backgroundSize ?? 'cover',
        backgroundRepeat: 'no-repeat',
      }}
    />
  );
}
