import * as React from 'react';

export default function ImageWrapper(props) {
  return (
    <div
      style={{
        height: 'auto',
        width: '100%',
        position: 'relative',
        ...props.style,
      }}
    >
      <picture>
        <source srcSet={props.src} type={props.type ?? 'image/svg'} />
        <img
          className="Image"
          src={props.src}
          alt={props.alt}
          style={{
            width: '100%',
            height: 'auto',
          }}
        />
      </picture>
    </div>
  );
}
