import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import nlNL from '../assets/lang/nl_NL/translations.json';

import Config from '../Config';

i18n
  .use(initReactI18next)
  .init({
    fallbackLng: Config.FallbackLanguage,
    lng: Config.Language,
    resources: {
      nl: {
        translations: nlNL,
      },
    },
    ns: ['translations'],
    defaultNS: 'translations',
  });

i18n.languages = ['nl'];

export default i18n;
