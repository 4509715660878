import * as React from 'react';

import Colors from '../../styles/Colors';

import TeamImage from '../../assets/img/Team.jpg';

export default function Team() {
  return (
    <>
      <div
        style={{
          paddingBottom: 5,
          height: 30,
          borderBottom: `solid 4px ${Colors.BLACK_MEDIUM}`,
          marginBottom: 25,
        }}
      />
      <div
        style={{
          width: '100%',
          minHeight: 500,
          maxHeight: 520,
          height: '100%',
          backgroundImage: `url('${TeamImage}')`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      />
    </>
  );
}
