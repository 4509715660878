import * as React from 'react';

import EmailInput from './EmailInput';
import TextInput from './TextInput';
import TextAreaInput from './TextAreaInput';

export default function Inputs(props) {
  const renderTypes = (e) => {
    switch (e) {
      case 'email':
        return (
          <EmailInput
            type="email"
            style={props.style}
            name={props.name}
            disabled={props.disabled}
            value={props.value}
            onChange={props.onChange}
            maxLength={props.maxLength}
            required={props.required}
            placeholder={props.placeholder}
            onKeyDown={props.onKeyDown}
            autocomplete={props.autocomplete}
          />
        );
      case 'textarea':
        return (
          <TextAreaInput
            type="textarea"
            style={props.style}
            name={props.name}
            disabled={props.disabled}
            value={props.value}
            onChange={props.onChange}
            maxLength={props.maxLength}
            required={props.required}
            placeholder={props.placeholder}
            onKeyDown={props.onKeyDown}
            autocomplete={props.autocomplete}
          />
        );
      default:
        return (
          <TextInput
            type="text"
            style={props.style}
            name={props.name}
            disabled={props.disabled}
            value={props.value}
            onChange={props.onChange}
            maxLength={props.maxLength}
            required={props.required}
            placeholder={props.placeholder}
            onKeyDown={props.onKeyDown}
            autocomplete={props.autocomplete}
          />
        );
    }
  };

  return (
    <>
      {renderTypes(props.type)}
    </>
  );
}
