import * as React from 'react';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';

import Colors from '../../../styles/Colors';
import Breakpoint from '../../../styles/Breakpoints';

import UseDimensions from '../../../services/UseDimensions';
import GetScrollHeight from '../../../services/GetScrollHeight';

import MainContainer from '../../Containers/MainContainer';

import SideMenu from './SideMenu';
import NavItem from './NavItem';

import Logo from '../../../assets/img/BourgondieLogoNoText.svg';
import LogoText from '../../../assets/img/BourgondieLogoText.svg';

export default function Header() {
  const { t } = useTranslation();
  const size = UseDimensions();
  const scrollHeight = GetScrollHeight();

  return (
    <div
      style={{
        height: 70,
        width: '100%',
        position: 'fixed',
        zIndex: 1000,
        transition: 'all 0.5s ease-in',
        backgroundColor: scrollHeight > 45 ? Colors.BLACK_MEDIUM : 'transparent',
      }}
    >
      <MainContainer>
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          height: 70,
          overflow: 'hidden',
          position: 'relative',
          zIndex: 1,
        }}
        >
          <a
            href="/"
            type="button"
            style={{
              paddingTop: 7,
              textDecoration: 'none',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <img
                src={Logo}
                alt="Bourgondie Logo"
                style={{
                  height: 85,
                  width: 85,
                  marginTop: -15,
                  marginLeft: -15,
                  position: 'relative',
                  zIndex: 1000,
                }}
              />
              <img
                src={LogoText}
                alt="Bourgondie Logo"
                style={{
                  height: 85,
                  width: 'auto',
                  marginTop: -11.5,
                  marginLeft: -35,
                  position: 'relative',
                  zIndex: 1000,
                }}
              />
            </div>
          </a>
          { size.width < Breakpoint.lg
            ? <SideMenu />
            : (
              <div style={{
                width: '100%',
                height: 70,
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
                alignItems: 'center',
              }}
              >
                <NavItem
                  path="/"
                  title={parse(t('Navigation.Menu.home'))}
                />
                <NavItem
                  path="/#team"
                  title={parse(t('Navigation.Menu.team'))}
                />
                <NavItem
                  path="/#locatie"
                  title={parse(t('Navigation.Menu.location'))}
                />
                <NavItem
                  path="/#contact"
                  title={parse(t('Navigation.Menu.contact'))}
                />
              </div>
            )}
        </div>
      </MainContainer>
    </div>
  );
}
