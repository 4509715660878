import * as React from 'react';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';

import UseDimensions from '../../services/UseDimensions';

import Colors from '../../styles/Colors';

import MainContainer from '../Containers/MainContainer';
import Text from '../Modules/Text';

export default function LastGameResult(props) {
  const { t } = useTranslation();
  const size = UseDimensions();

  const rm = (e) => e.replace(/(?:^|\W)MSE [1-9][0-9]?$|^100(?:$|\W)/, '');

  return (
    <>
      <MainContainer>
        <div
          style={{
            height: 8,
            width: '100%',
            backgroundColor: Colors.WHITE,
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <div
            style={{
              width: 260,
              height: 10,
              backgroundColor: Colors.BLACK,
              marginTop: -1,
            }}
          >
            <Text
              textAlign="center"
              marginTop="-9px"
            >
              {parse(t('LastGameResult.title'))}
            </Text>
          </div>
        </div>
      </MainContainer>

      <div
        style={{
          display: 'flex',
          flexDirection: size.width < 1200 ? 'column' : 'row',
        }}
      >

        <div
          style={{
            marginBottom: -25,
            width: size.width < 1200 ? '100%' : '33.333333333%',
          }}
        >
          <div
            style={{
              marginTop: size.width < 1200 ? 30 : 0,
              width: '100%',
              overflow: 'hidden',
              height: 150,
              display: 'flex',
              alignItems: 'center',
              justifyContent: size.width < 1200 ? 'center' : 'right',
            }}
          >
            <Text
              textAlign={size.width < 1200 ? 'center' : 'right'}
              color={Colors.BLACK_MEDIUM}
              fontSize="6.9em"
            >
              {props.win === 'thuis_ploeg' ? 'WIN' : 'LOSS'}
            </Text>
          </div>
          <MainContainer>
            <div
              style={{
                height: 150,
                marginTop: -150,
                display: 'flex',
                alignItems: 'center',
                position: 'relative',
              }}
            >
              <Text
                textAlign={size.width < 1200 ? 'center' : 'right'}
                color={rm(props.home[0]) === 'Bourgondie' ? Colors.ORANGE : Colors.WHITE}
              >
                {rm(props.home[0]) === 'Bourgondie' ? 'Bourgondië' : rm(props.home[0])}
              </Text>
            </div>

          </MainContainer>
        </div>

        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: size.width < 1200 ? 'column' : 'row',
            width: size.width < 1200 ? '100%' : '33.333333333%',
          }}
        >
          <Text
            textAlign={size.width < 1200 ? 'center' : 'right'}
            fontSize="2.5em"
          >
            {props.home[1]}
          </Text>
          <Text
            textAlign="center"
          >
            {parse(t('LastGameResult.vs'))}
          </Text>
          <Text
            textAlign={size.width < 1200 ? 'center' : 'left'}
            fontSize="2.5em"
          >
            {props.guest[1]}
          </Text>
        </div>

        <div
          style={{
            marginTop: size.width < 1200 ? -50 : 0,
            width: size.width < 1200 ? '100%' : '33.333333333%',
          }}
        >
          <div
            style={{
              marginTop: size.width < 1200 ? 30 : 0,
              width: '100%',
              overflow: 'hidden',
              height: 150,
              display: 'flex',
              alignItems: 'center',
              justifyContent: size.width < 1200 ? 'center' : 'left',
            }}
          >
            <Text
              textAlign={size.width < 1200 ? 'center' : 'left'}
              color={Colors.BLACK_MEDIUM}
              fontSize="6.9em"
              width="auto"
            >
              {props.win === 'uit_ploeg' ? 'WIN' : 'LOSS'}
            </Text>
          </div>
          <MainContainer>
            <div
              style={{
                height: 150,
                marginTop: -150,
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Text
                textAlign={size.width < 1200 ? 'center' : 'left'}
                color={rm(props.guest[0]) === 'Bourgondie' ? Colors.ORANGE : Colors.WHITE}
              >
                {rm(props.guest[0]) === 'Bourgondie' ? 'Bourgondië' : rm(props.guest[0])}
              </Text>
            </div>
          </MainContainer>
        </div>
      </div>
    </>
  );
}
