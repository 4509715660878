import * as React from 'react';

import Breakpoint from '../../styles/Breakpoints';

import UseDimensions from '../../services/UseDimensions';

export default function WideContainer(props) {
  const size = UseDimensions();

  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <div
        style={{
          height: 'auto',
          width: size.width < Breakpoint.lg ? '94%' : '75%',
          marginLeft: size.width < Breakpoint.lg ? '3%' : '12.5%',
          marginRight: size.width < Breakpoint.lg ? '3%' : '12.5%',
          maxWidth: 1600,
          ...props.style,
        }}
      >
        {props.children}
      </div>
    </div>
  );
}
