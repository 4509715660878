import * as React from 'react';

import Config from '../../../Config';
import Colors from '../../../styles/Colors';

export default function Text(props) {
  return (
    <p
      className="TextWithLink"
      style={{
        color: props.color ?? Colors.WHITE,
        fontFamily: props.fontFamily ?? Config.FontFamily,
        fontStyle: props.fontStyle ?? 'normal',
        fontWeight: props.fontWeight ?? 100,
        fontSize: props.fontSize ?? '1.2em',
        textAlign: props.textAlign ?? 'left',
        letterSpacing: 1.2,
        width: props.width ?? '100%',
        marginBottom: 0,
        marginTop: props.marginTop ?? 0,
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: props.paddingLeft ?? 0,
      }}
    >
      {props.children}
    </p>
  );
}
