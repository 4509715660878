import * as React from 'react';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';

import Breakpoint from '../../styles/Breakpoints';

import UseDimensions from '../../services/UseDimensions';

import MainContainer from '../Containers/MainContainer';

import FullScreenImage from '../Modules/FullScreenImage';
import Image from '../Modules/Image';
import Text from '../Modules/Text';
import Buttons from '../Modules/Text/Buttons';

import LandingBannerBackground from '../../assets/img/BourgondieLanding.svg';
import BannerMobile from '../../assets/img/BourgondieBannerMobile.svg';
import BannerDesktop from '../../assets/img/BourgondieBanner.svg';

export default function Landing() {
  const { t } = useTranslation();
  const size = UseDimensions();
  return (
    <>
      <div
        style={{
          height: 'auto',
          width: '100%',
          overflow: 'hidden',
        }}
      >
        <MainContainer
          style={{
            position: 'relative',
          }}
        >
          <div
            style={{
              position: 'absolute',
              height: '90vh',
              minHeight: 580,
              width: size.width < Breakpoint.xs ? '100%' : '80%',
              marginLeft: size.width < Breakpoint.xs ? 0 : '10%',
              marginRight: size.width < Breakpoint.xs ? 0 : '10%',
              overflow: 'hidden',
              display: 'flex',
              justifyContent: 'center',
              zIndex: 0,
            }}
          >
            { size.width < Breakpoint.xs
              ? (
                <div
                  style={{
                    width: '100%',
                    maxWidth: 300,
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Image
                    src={BannerMobile}
                  />
                </div>
              )
              : (
                <div
                  style={{
                    width: '90%',
                    minWidth: 470,
                    display: 'flex',
                    alignItems: 'center',
                    marginTop: 0,
                  }}
                >
                  <Image
                    src={BannerDesktop}
                  />
                </div>
              )}
          </div>
        </MainContainer>
        <div
          style={{
            height: '90vh',
            width: '100%',
          }}
        >
          <FullScreenImage
            src={LandingBannerBackground}
            backgroundPosition="center"
            height="100vh"
            width="inherit"
            minWidth="inherit"
          />
        </div>
        <MainContainer
          style={{
            position: 'relative',
            zIndex: 1,
            marginBottom: 400,
            marginTop: size.width < Breakpoint.xs ? 0 : -200,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              marginBottom: 15,
            }}
          >
            <Text
              textAlign="center"
            >
              {parse(t('Landing.button_text'))}
            </Text>
          </div>
          <Buttons
            type="main"
            title={parse(t('Landing.button_main'))}
            path="/#contact"
          />
        </MainContainer>
      </div>
    </>
  );
}
