import * as React from 'react';

import Colors from '../../styles/Colors';

import Text from '../Modules/Text';

export default function TextBox(props) {
  return (
    <>
      <div
        style={{
          borderBottom: `solid 4px ${Colors.BLACK_MEDIUM}`,
          paddingBottom: 10,
          marginBottom: 20,
        }}
      >
        <Text>{props.title}</Text>
      </div>
      <Text
        fontFamily="Poppins"
        fontWeight="300"
      >
        {props.text}
      </Text>
    </>
  );
}
