import * as React from 'react';
import {
  Redirect,
} from 'react-router-dom';

export default function Page404() {
  return (
    <Redirect to="/" />
  );
}
