import * as React from 'react';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';

import Colors from '../../styles/Colors';

import MainContainer from '../Containers/MainContainer';

import Text from '../Modules/Text';

export default function MatchSchedule() {
  const { t } = useTranslation();

  const currentYear = new Date().getFullYear();

  return (
    <>
      <MainContainer>
        <div
          style={{
            borderTop: `solid 4px ${Colors.BLACK_MEDIUM}`,
            marginTop: 50,
            height: 'auto',
            width: '100%',
            marginBottom: 0,
            paddingTop: 10,
            paddingBottom: 10,
          }}
        >
          <Text
            fontFamily="Poppins"
            fontWeight="300"
            fontSize="1em"
          >
            &copy;
            1974
            {' - '}
            {currentYear}
            {' '}
            {parse(t('Footer.text'))}
          </Text>
        </div>
      </MainContainer>
    </>
  );
}
