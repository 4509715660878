import * as React from 'react';

import Colors from '../../../../styles/Colors';

export default function TextInput(props) {
  return (
    <input
      type={props.type}
      name={props.name}
      disabled={!!props.disabled}
      value={props.value}
      onChange={props.onChange}
      maxLength={props.maxLength}
      placeholder={props.placeholder}
      onKeyDown={props.onKeyDown}
      autoComplete={props.autocomplete}
      style={{
        width: '100%',
        height: 40,
        borderBottom: props.error ? `solid 4px ${Colors.RED}` : `solid 4px ${Colors.BLACK_LIGHT}`,
        borderTop: 'none',
        borderRight: 'none',
        borderLeft: 'none',
        backgroundColor: Colors.BLACK_MEDIUM,
        fontFamily: 'Poppins',
        fontWeight: 300,
        fontSize: '1.2em',
        letterSpacing: 1.2,
        color: Colors.WHITE,
        boxSizing: 'border-box',
        WebKitBoxSizing: 'border-box',
        MozBoxSizing: 'border-box',
        paddingLeft: 10,
        paddingRight: 10,
      }}
    />
  );
}
