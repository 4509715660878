import * as React from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import parse from 'html-react-parser';

import Container from '../Containers/Container';

import Text from '../Modules/Text';

import Breakpoint from '../../styles/Breakpoints';
import Colors from '../../styles/Colors';

import UseDimensions from '../../services/UseDimensions';

import Buttons from '../Modules/Text/Buttons';

export default function Address(props) {
  const { t } = useTranslation();
  const size = UseDimensions();

  const days = ['Zondag', 'Maandag', 'Dinsdag', 'Woensdag', 'Donderdag', 'Vrijdag', 'Zaterdag'];
  const months = ['jan.', 'feb.', 'mrt.', 'apr.', 'mei', 'jun.', 'jul.', 'aug.', 'sept.', 'okt.', 'nov.', 'dec.'];

  const gameDate = moment(props.firstGame ? props.firstGame.datum : 0)
    .subtract({ minutes: 30 })
    .toDate();

  const readableGameDate = `${days[gameDate.getDay()]} ${gameDate.getDate()} ${months[gameDate.getMonth()]}`;
  const readableGameTime = `${gameDate.getHours() + (gameDate.getHours() < 10 ? '0' : '')}:${gameDate.getMinutes()}${gameDate.getMinutes() < 10 ? '0' : ''}`;

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          marginTop: size.width < Breakpoint.md ? 50 : 0,
          borderBottom: `solid 4px ${Colors.BLACK_MEDIUM}`,
          paddingBottom: 5,
          height: 30,
          marginBottom: 20,
        }}
      >
        <Text>{parse(t('Address.title'))}</Text>
      </div>
      <div>
        <Text
          fontFamily="Poppins"
          fontWeight="300"
        >
          {parse(t('Address.training_text'))}
        </Text>
        <br />
        <Container>
          <div>
            <Text>
              Maandag
            </Text>
          </div>
          <div>
            <Text>
              20:00 - 21:00
            </Text>
          </div>
        </Container>
        <div
          style={{
            marginTop: 15,
          }}
        >
          <Container>
            <div>
              <Text>Woensdag</Text>
            </div>
            <div>
              <Text>20:00 - 21:00</Text>
            </div>
          </Container>
        </div>
        <div
          style={{
            marginTop: 15,
          }}
        >
          <Container>
            <div>
              <Text>{readableGameDate}</Text>
            </div>
            <div>
              <Text>{readableGameTime}</Text>
            </div>
          </Container>
        </div>
        <div
          style={{
            marginTop: 50,
          }}
        >
          <Text
            fontFamily="Poppins"
            fontWeight="300"
          >
            {parse(t('Address.address'))}
          </Text>
          <div style={{ marginTop: 20, marginBottom: 60 }}>
            <Buttons
              type="secondary"
              title="Open in Google Maps"
              onClick={() => window.open('https://goo.gl/maps/tP1fb8oTzkudstgNA')}
            />
          </div>
        </div>
      </div>
    </>
  );
}
