import * as React from 'react';
import { HashLink } from 'react-router-hash-link';

import Colors from '../../../../styles/Colors';

import Text from '../index';

function Button(e) {
  return (
    <button
      type="button"
      disabled={e.props.disabled}
      onClick={e.props.onClick ?? null}
      className={e.props.disabled ? 'ButtonDisabled' : ''}
      style={{
        width: '100%',
        maxWidth: e.props.maxWidth ?? 300,
        height: 50,
        backgroundColor: e.props.disabled
          ? Colors.BLACK_MEDIUM
          : e.props.backgroundColor ?? Colors.ORANGE,
        borderRadius: 7.5,
        border: 'none',
        cursor: 'pointer',
      }}
    >
      <Text
        textAlign="center"
        color={e.props.disabled ? Colors.BLACK_LIGHT : Colors.WHITE}
      >
        {e.props.title}
      </Text>
    </button>
  );
}

export default function MainButton(props) {
  if (props.disabled) {
    return <Button props={props} />;
  }
  if (props.path) {
    return (
      <HashLink
        to={props.path ?? '/'}
        scroll={null}
        rel="noopener"
        style={{
          width: '100%',
          maxWidth: 300,
          marginBottom: 15,
        }}
      >
        <Button props={props} />
      </HashLink>
    );
  }
  return (
    <Button
      props={props}
    />
  );
}
