import * as React from 'react';
import { HashLink } from 'react-router-hash-link';

import Colors from '../../../styles/Colors';

import UseDimensions from '../../../services/UseDimensions';

function NavButton(e) {
  const size = UseDimensions();
  return (
    <button
      type="button"
      onClick={e.props.onClick}
      style={{
        display: 'flex',
        alignItems: 'center',
        textDecoration: 'none',
        height: e.props.mobile ? 50 : 70,
        marginLeft: e.props.mobile ? 0 : size.width < 1550 ? 30 : 70,
        backgroundColor: e.props.mobile ? Colors.BLACK_MEDIUM : 'transparent',
        width: e.props.mobile ? '100%' : 'auto',
        marginBottom: 10,
        borderTopLeftRadius: e.props.mobile ? 10 : 0,
        borderBottomLeftRadius: e.props.mobile ? 10 : 0,
        paddingLeft: 10,
        border: 'none',
      }}
      rel="noopener"
    >
      <p style={{
        color: Colors.WHITE,
        fontFamily: 'Krona One',
        fontWeight: 400,
        fontStyle: 'normal',
        fontSize: e.props.mobile ? '1.5em' : '1.2em',
        textAlign: e.props.mobile ? 'left' : 'center',
        height: 7,
        marginTop: e.props.mobile ? 0 : 10,
      }}
      >
        {e.props.title}
      </p>
    </button>
  );
}

export default function NavItem(props) {
  const size = UseDimensions();

  if (props.onClick) {
    return <NavButton props={props} />;
  }
  return (
    <HashLink
      to={props.path}
      style={{
        display: 'flex',
        alignItems: 'center',
        textDecoration: 'none',
        height: props.mobile ? 50 : 70,
        marginLeft: props.mobile ? 0 : size.width < 1550 ? 30 : 70,
        backgroundColor: props.mobile ? Colors.BLACK_MEDIUM : 'transparent',
        width: props.mobile ? '100%' : 'auto',
        marginBottom: 10,
        borderTopLeftRadius: props.mobile ? 10 : 0,
        borderBottomLeftRadius: props.mobile ? 10 : 0,
        paddingLeft: 10,
      }}
      rel="noopener"
    >
      <p style={{
        color: Colors.WHITE,
        fontFamily: 'Krona One',
        fontWeight: 400,
        fontStyle: 'normal',
        fontSize: props.mobile ? '1.5em' : '1.2em',
        textAlign: props.mobile ? 'left' : 'center',
        height: 7,
        marginTop: props.mobile ? 0 : 10,
      }}
      >
        {props.title}
      </p>
    </HashLink>
  );
}
