const Config = {

  /* ===
    Styling Settings
  === */
  FontFamily: 'Krona One',

  /* ===
    Language Settings
  === */
  Language: 'nl',
  FallbackLanguage: 'nl',

  /* ===
    Google Recaptcha
  === */
  GoogleRecaptha_SiteKey: '6LdwLhsdAAAAAF7lwuVlQ0wyGbNmJEK5cvn_0o0s',

  /* ===
    API Settings
  === */
  apiUrl: 'https://cdn.nielskohlenberg.nl/bourgondie',

};

export default Config;
