import * as React from 'react';

import Header from './Modules/Header';
import Footer from './Sections/Footer';

export default function Layout(props) {
  return (
    <>
      <Header />
      <main>
        { props.children }
      </main>
      <Footer />
    </>
  );
}
