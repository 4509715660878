/* eslint-disable */
import React, { useRef, useEffect, useState } from 'react';
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax

import Colors from '../../styles/Colors';

export default function Map() {

  mapboxgl.accessToken = 'pk.eyJ1IjoibmllbHNrb2hsZW5iZXJnIiwiYSI6ImNrYm9tcm92ajIycmMzMW15NXpqMGtnZGcifQ.oArRa52H9v2Y-x1fKU1D_w';

  const mapContainer = useRef(null);
  const map = useRef(null);
  const [lng, setLng] = useState(5.336254);
  const [lat, setLat] = useState(51.966104);
  const [zoom, setZoom] = useState(15);

  useEffect(() => {
    if (map.current) return; // initialize map only once
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/mapbox/dark-v10',
      center: [lng, lat],
      zoom: zoom
    });

    let marker = new mapboxgl.Marker({
      color: 'rgb(234, 131, 65)'
    })
        .setLngLat([5.336254, 51.966104])
        .addTo(map.current);
  });

  return (
    <>
      <div
        style={{
          paddingBottom: 5,
          height: 30,
          borderBottom: `solid 4px ${Colors.BLACK_MEDIUM}`,
          marginBottom: 25,
        }}
      />
      <div
        style={{
          width: '100%',
          minHeight: 200,
          maxHeight: 465,
          height: '100%',
        }}
      >
        <div ref={mapContainer} style={{height: 400}}></div>
      </div>
    </>
  );
}
