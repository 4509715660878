import * as React from 'react';

import UseDimensions from '../../../services/UseDimensions';

import Colors from '../../../styles/Colors';
import Breakpoint from '../../../styles/Breakpoints';

import Container from '../../Containers/Container';

export default function TabSelector(props) {
  const size = UseDimensions();
  const [selected, setSelected] = React.useState();

  React.useEffect(() => {
    props.callback(props.options[0]);
    setSelected(props.options[0]);
  }, []);

  const handleOnClick = (title) => {
    props.callback(title);
    setSelected(title);
  };

  return (
    <>
      <Container breakpoint="sm">
        {props.options.map((title) => (
          <button
            className="Button"
            key={title}
            type="button"
            onClick={() => handleOnClick(title)}
            style={{
              height: 40,
              width: '100%',
              borderBottom: size.width > Breakpoint.sm ? selected === title ? `solid 4px ${Colors.ORANGE}` : `solid 4px ${Colors.BLACK_MEDIUM}` : 'none',
              borderLeft: size.width < Breakpoint.sm ? selected === title ? `solid 4px ${Colors.ORANGE}` : `solid 4px ${Colors.BLACK_MEDIUM}` : 'none',
              backgroundColor: size.width < Breakpoint.sm ? selected === title ? Colors.BLACK_MEDIUM : 'transparent' : 'transparent',
              borderTop: 'none',
              borderRight: 'none',
              fontFamily: 'Poppins',
              fontWeight: 300,
              fontSize: '1.2em',
              fontStyle: 'normal',
              letterSpacing: 1.2,
              color: Colors.WHITE,
              textAlign: size.width < Breakpoint.sm ? 'left' : 'center',
              paddingLeft: size.width < Breakpoint.sm ? 10 : 0,
              marginBottom: size.width < Breakpoint.sm ? 10 : 0,
            }}
          >
            {title}
          </button>
        ))}
      </Container>
    </>
  );
}
