import * as React from 'react';

import Colors from '../../../styles/Colors';

export default function Title(props) {
  return (
    <div style={{
      height: 'auto',
      width: '100%',
      marginBottom: 10,
    }}
    >
      <p style={{
        color: Colors.WHITE,
        fontFamily: 'Poppins',
        fontWeight: 300,
        fontSize: '1.2em',
        letterSpacing: 1.2,
        width: props.width ?? '100%',
        marginBottom: 0,
        marginTop: 0,
        paddingTop: 0,
        paddingBottom: 0,
        position: 'relative',
      }}
      >
        {props.children}
        { !props.required
          && (
          <i style={{
            marginLeft: 13,
            fontSize: '0.7em',
            color: Colors.WHITE,
          }}
          >
            Optioneel
          </i>
          )}
      </p>
    </div>
  );
}
