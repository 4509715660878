import * as React from 'react';

export default function GetScrollHeight() {
  const [scrollHeight, setScrollHeight] = React.useState(0);

  React.useEffect(() => {
    function handleScroll() {
      setScrollHeight(window.pageYOffset);
    }

    window.addEventListener('scroll', handleScroll);
    handleScroll();

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return scrollHeight;
}
