import * as React from 'react';

import Colors from '../../../../styles/Colors';

export default function TextAreaInput(props) {
  return (
    <textarea
      name={props.name}
      disabled={!!props.disabled}
      value={props.value}
      onChange={props.onChange}
      maxLength={props.maxLength}
      placeholder={props.placeholder}
      onKeyDown={props.onKeyDown}
      style={{
        width: '100%',
        height: 300,
        minHeight: 300,
        maxHeight: 400,
        borderTopRightRadius: 7.5,
        borderTop: props.error ? `solid 4px ${Colors.RED}` : `solid 4px ${Colors.BLACK_LIGHT}`,
        borderRight: props.error ? `solid 4px ${Colors.RED}` : `solid 4px ${Colors.BLACK_LIGHT}`,
        borderLeft: 'none',
        borderBottom: 'none',
        backgroundColor: Colors.BLACK,
        fontFamily: 'Poppins',
        fontWeight: 100,
        fontSize: '1.2em',
        letterSpacing: 1.2,
        color: Colors.WHITE,
        boxSizing: 'border-box',
        WebKitBoxSizing: 'border-box',
        MozBoxSizing: 'border-box',
        padding: 10,
        resize: 'vertical',
      }}
    />
  );
}
