import * as React from 'react';

import axios from 'axios';

import Config from '../Config';

import Container from '../components/Containers/Container';
import MainContainer from '../components/Containers/MainContainer';

import Landing from '../components/Sections/Landing';
import LastGameResult from '../components/Sections/LastGameResult';
import Team from '../components/Sections/Team';
import Standings from '../components/Sections/Standings';
import Address from '../components/Sections/Address';
import Map from '../components/Sections/Map';
import Contact from '../components/Sections/Contact';
import BasketballsCool from '../components/Sections/BasketballsCool';
import BasketballsCoolAddress from '../components/Sections/BasketballsCoolAddress';

export default function IndexPage() {
  const [games, setGames] = React.useState([]);
  const [standings, setStandings] = React.useState([]);

  React.useEffect(() => {
    axios.get(`${Config.apiUrl}/wedstrijden.php`)
      .then((response) => {
        setGames(response.data);
      });

    axios.get(`${Config.apiUrl}/stand.php`)
      .then((response) => {
        setStandings(response.data);
      });
  }, []);

  const playedGames = games.filter((i) => i.status === 'gespeeld');
  const lastGame = playedGames[playedGames.length - 1] ?? {
    thuis_ploeg: '', score_thuis: 0, uit_ploeg: '', score_uit: 0, winst: '',
  };

  const toPlayGames = games.filter((i) => i.status === 'planning');
  const toPlayHomeGames = toPlayGames.filter((i) => i.loc_naam === 'Sporthal Marienhoeve');

  return (
    <>
      <Landing />

      <div
        style={{
          marginTop: -200,
        }}
      >
        <LastGameResult
          home={[lastGame.thuis_ploeg, lastGame.score_thuis]}
          guest={[lastGame.uit_ploeg, lastGame.score_uit]}
          win={lastGame.winst}
        />
      </div>
      <div id="team" />

      <div
        style={{
          marginTop: 30,
        }}
      >
        <MainContainer>
          <Container share={[60, 40]}>
            <Team />
            <Standings data={standings} />
          </Container>
        </MainContainer>
      </div>
      <div id="corona" />

      <div
        style={{
          marginTop: 120,
          position: 'relative',
        }}
      >
        <MainContainer>
          <Container share={[60, 40]}>
            <BasketballsCool />
            <BasketballsCoolAddress />
          </Container>
        </MainContainer>
      </div>
      <div id="locatie" />

      <div
        style={{
          marginTop: 120,
        }}
      >
        <MainContainer>
          <Container share={[50, 50]} invert>
            <Map />
            <Address firstGame={toPlayHomeGames[0]} />
          </Container>
        </MainContainer>
      </div>
      <div id="contact" />

      <div
        style={{
          marginTop: 120,
        }}
      >
        <MainContainer>
          <Container share={[60, 40]}>
            <Contact />
            <></>
          </Container>
        </MainContainer>
      </div>
    </>
  );
}
