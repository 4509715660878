import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';
import axios from 'axios';

import Config from '../../Config';

import Colors from '../../styles/Colors';
import Breakpoint from '../../styles/Breakpoints';

import UseDimensions from '../../services/UseDimensions';

import Container from '../Containers/Container';

import Text from '../Modules/Text';
import Label from '../Modules/Text/Label';
import Buttons from '../Modules/Text/Buttons';
import TabSelector from '../Modules/Forms/TabSelector';
import Input from '../Modules/Forms/Input';

export default function Contact() {
  const { t } = useTranslation();
  const size = UseDimensions();

  const SITE_KEY = Config.GoogleRecaptha_SiteKey;

  const [selected, setSelected] = React.useState();
  const [sending, setSending] = React.useState(false);
  const [firstName, setFirstname] = React.useState('');
  const [lastName, setLastname] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [emailConfirm, setEmailConfirm] = React.useState('');
  const [extraInfo, setExtraInfo] = React.useState('');
  const [feedback, setFeedback] = React.useState('');

  const callbackTabSelector = (e) => setSelected(e);

  const buttonDisabled = () => {
    if (firstName === '') return true;
    if (lastName === '') return true;
    if (email.search(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g) === -1) return true;
    if (email !== emailConfirm) return true;
    if (extraInfo === '' && selected === 'Overige') return true;

    return false;
  };

  const submitForm = () => {
    if (buttonDisabled() || sending) return;

    setSending(true);

    window.grecaptcha.ready(() => {
      window.grecaptcha.execute(SITE_KEY, { action: 'submit' }).then((token) => {
        axios.post(`${Config.apiUrl}/send_email.php`, {
          name: `${firstName} ${lastName}`,
          email,
          subject: `[${selected !== 'Overige' ? 'Aanvraag' : 'Vraag'}] ${selected}`,
          message: extraInfo,
          reCaptchaToken: token,
        })
          .then(() => {
            setFirstname('');
            setLastname('');
            setEmail('');
            setEmailConfirm('');
            setExtraInfo('');
            setSending(false);
            setFeedback(`Aanvraag ${selected.toLowerCase()} verzonden!`);
          }, () => {
            setFeedback('Er is iets fout gegaan...');
          })
          .catch(() => {
            setSending(false);
          });
      });
    });
  };

  /* eslint-disable */
  useEffect(() => {
    const loadScriptByURL = (id, url, callback) => {
      const isScriptExist = document.getElementById(id);

      if (!isScriptExist) {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = url;
        script.id = id;
        script.onload = function onload() {
          if (callback) callback();
        };
        document.body.appendChild(script);
      }

      if (isScriptExist && callback) callback();
    };

    // load the script by passing the URL
    loadScriptByURL('recaptcha-key', `https://www.google.com/recaptcha/api.js?render=${SITE_KEY}`);
  }, [SITE_KEY]);

  /* eslint-enable */

  return (
    <>
      <div
        style={{
          borderBottom: `solid 4px ${Colors.BLACK_MEDIUM}`,
          paddingBottom: 10,
          marginBottom: 20,
        }}
      >
        <Text>{parse(t('Contact.title'))}</Text>
      </div>
      <div>

        <div
          style={{
            marginTop: 30,
            marginBottom: 30,
          }}
        >
          <TabSelector
            label={parse(t('Contact.subject'))}
            options={[
              parse(t('Contact.Subjects.training')),
              parse(t('Contact.Subjects.member')),
              parse(t('Contact.Subjects.other')),
            ]}
            callback={callbackTabSelector}
          />
        </div>
        <form
          style={{
            marginBottom: 20,
          }}
        >
          <Container>
            <div
              style={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
              }}
            >
              <Label required>Voornaam:</Label>
              <Input
                onChange={(e) => setFirstname(e.target.value)}
                name="first-name"
                autocomplete="given-name"
                required
                value={firstName}
              />
            </div>
            <div
              style={{
                height: '100%',
                marginTop: size.width < Breakpoint.md ? 20 : 0,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
              }}
            >
              <Label required>Achternaam:</Label>
              <Input
                onChange={(e) => setLastname(e.target.value)}
                name="last-name"
                autocomplete="family-name"
                required
                value={lastName}
              />
            </div>
          </Container>
          <div
            style={{
              marginTop: 20,
            }}
          >
            <Container>
              <div
                style={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                }}
              >
                <Label required>E-mailadres:</Label>
                <Input
                  onChange={(e) => setEmail(e.target.value)}
                  name="email"
                  autocomplete="email"
                  required
                  value={email}
                />
              </div>
              <div
                style={{
                  height: '100%',
                  marginTop: size.width < Breakpoint.md ? 20 : 0,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                }}
              >
                <Label required>Bevestiging e-mailadres:</Label>
                <Input
                  onChange={(e) => setEmailConfirm(e.target.value)}
                  type="email"
                  name="confirm-email"
                  required
                  value={emailConfirm}
                />
              </div>
            </Container>
          </div>
          <div
            style={{
              marginTop: 20,
              marginBottom: 15,
            }}
          >
            <Label required={selected === 'Overige'}>Vragen of aanvullende opmerkingen:</Label>
            <Input
              onChange={(e) => setExtraInfo(e.target.value)}
              type="textarea"
              name="message"
              placeholder="..."
              value={extraInfo}
            />
          </div>
          <div
            style={{
              width: '100%',
              textAlign: size.width < Breakpoint.sm ? 'center' : 'left',
            }}
          >
            {
              feedback && (
                <div
                  style={{
                    marginTop: 5,
                    marginBottom: 15,
                  }}
                >
                  <Text
                    fontWeight={300}
                    fontFamily="Poppins"
                  >
                    {feedback}
                  </Text>
                </div>
              )
            }
            <Buttons
              title="Versturen"
              disabled={buttonDisabled()}
              maxWidth={size.width < Breakpoint.sm ? '100%' : '48%'}
              onClick={() => (buttonDisabled() ? null : submitForm())}
            />
          </div>
        </form>
        <Text
          fontFamily="Poppins"
          fontWeight="300"
          fontSize="0.8em"
        >
          {parse(t('Contact.legal'))}
        </Text>
      </div>
    </>
  );
}
