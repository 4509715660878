import * as React from 'react';
import { HashLink } from 'react-router-hash-link';

import Colors from '../../../../styles/Colors';

import Text from '../index';

function Button(e) {
  return (
    <button
      type="button"
      disabled={e.props.disabled}
      onClick={e.props.onClick ?? null}
      style={{
        width: '100%',
        maxWidth: 300,
        height: 50,
        backgroundColor: e.props.backgroundColor ?? 'transparent',
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        borderTopRightRadius: 7.5,
        borderBottomRightRadius: 0,
        borderTop: `solid 4px ${e.props.disabled ? Colors.BLACK_MEDIUM : Colors.WHITE}`,
        borderRight: `solid 4px ${e.props.disabled ? Colors.BLACK_MEDIUM : Colors.WHITE}`,
        borderLeft: 'none',
        borderBottom: 'none',
        cursor: 'pointer',
      }}
    >
      <Text
        textAlign="center"
        color={e.props.disabled ? Colors.BLACK_LIGHT : Colors.WHITE}
      >
        {e.props.title}
      </Text>
    </button>
  );
}

export default function SecondaryButton(props) {
  if (props.disabled || props.path) {
    return <Button props={props} />;
  }
  return (
    <HashLink
      to={props.path ?? '/'}
      scroll={null}
      rel="noopener"
      style={{
        width: '100%',
        maxWidth: 300,
        marginBottom: 15,
      }}
    >
      <Button props={props} />
    </HashLink>
  );
}
