import React from 'react';
import ReactDOM from 'react-dom';
import {
  BrowserRouter,
  Route,
  Switch,
} from 'react-router-dom';
import ReactGA from 'react-ga4';

import reportWebVitals from './reportWebVitals';

import ScrollToTop from './services/ScrollToTop';
import './services/Localize';

import Layout from './components/Layout';

import Index from './pages/index';
import Page404 from './pages/404';

import './styles/Global.css';
import 'mapbox-gl/dist/mapbox-gl.css';

ReactGA.initialize('G-KXKS62Q4BY');
ReactGA.send({ hitType: 'pageview' });

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Layout>
        <ScrollToTop>
          <Switch>

            <Route exact path="/" component={Index} />

            {/* Error pages */}
            <Route exact path="*" component={Page404} />

          </Switch>
        </ScrollToTop>
      </Layout>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
