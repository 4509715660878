import * as React from 'react';

import MainButton from './MainButton';
import SecondaryButton from './SecondaryButton';

export default function Buttons(props) {
  const renderTypes = (e) => {
    switch (e) {
      case 'secondary':
        return (
          <SecondaryButton
            path={props.path}
            title={props.title}
            backgroundColor={props.backgroundColor}
            onClick={props.onClick}
            disabled={props.disabled}
            maxWidth={props.maxWidth}
          />
        );
      default:
        return (
          <MainButton
            path={props.path}
            title={props.title}
            backgroundColor={props.backgroundColor}
            onClick={props.onClick}
            disabled={props.disabled}
            maxWidth={props.maxWidth}
          />
        );
    }
  };

  return (
    <>
      {renderTypes(props.type)}
    </>
  );
}
