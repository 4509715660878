const Colors = {
  // Basic Colors
  RED: '#FF0000',
  WHITE: '#E0E0E0',
  ORANGE: '#EA8341',
  BLACK_PURE: '#101010',
  BLACK: '#121212',
  BLACK_MEDIUM: '#1E1E1E',
  BLACK_LIGHT: '#383838',
};

export default Colors;
